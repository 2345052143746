import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getUsers = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.user.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getCompanyName = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.company.getCompanyName}`, { headers })
    .then(res => {
      resolve(res.data.companies)
    })
    .catch(err => {
      reject(err)
    })
})

export const getRoles = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.user.getRoles}`, { headers })
    .then(res => {
      resolve(res.data.roles)
    })
    .catch(err => {
      reject(err)
    })
})

export const create = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.user.create}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const editUser = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.user.edit}/${id}`, { headers })
    .then(res => {
      if (res.data.success) {
        resolve(res.data)
      }
    })
    .catch(err => {
      reject(err)
    })
})

export const update = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.user.update}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const deleteUser = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.user.delete}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getUsersByRole = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.user.getUsersByRole}`, params, { headers })
    .then(res => {
      const result = res.data.users.map(user => ({
        id: user.id,
        name: `${user.firstname ? user.firstname : ''} ${user.middlename ? user.middlename : ''} ${user.lastname ? user.lastname : ''}`,
      }))
      resolve(result)
    })
    .catch(err => {
      reject(err)
    })
})
