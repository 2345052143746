<template>
  <b-row>
    <b-col cols="12">
      <b-card-body>
        <b-row class="mb-1">
          <b-form-group label="User Role Management:">
            <b-form-checkbox-group
              id="roles-group-1"
              v-model="custom_roles"
              :options="options"
              name="flavour-1"
              class="demo-inline-spacing"
              @change="roleChange"
            />
          </b-form-group>
        </b-row>
      </b-card-body>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardBody, BFormGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    roles: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      custom_roles: [],
      options: [
        { text: 'Profile', value: 'Profile' },
        { text: 'Dashboard', value: 'Dashboard' },
        { text: 'Company', value: 'Company' },
        { text: 'Trips', value: 'Trips' },
        { text: 'Load Board', value: 'Load Board' },
        { text: 'Payments', value: 'Payments' },
        { text: 'Invoices', value: 'Invoices' },
        { text: 'Facility', value: 'Facility' },
        { text: 'Users', value: 'Users' },
        { text: 'Vendors', value: 'Vendors' },
        { text: 'Assets', value: 'Assets' },
        { text: 'Report', value: 'Report' },
        { text: 'Warehouse', value: 'Warehouse' },
        { text: 'callbackQBO', value: 'callbackQBO' },
      ],
    }
  },
  watch: {
    roles(value) {
      this.custom_roles = value.split(',')
    },
  },
  mounted() {
    this.custom_roles = this.roles.split(',')
  },
  methods: {
    roleChange(e) {
      this.$emit('roleChange', e)
    },
  },
}
</script>

<style>

</style>
