<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>

          <b-form @submit.prevent="submit">
            <b-row>

              <!-- First Name -->
              <b-col md="6">
                <b-form-group
                  label="First Name"
                  label-for="mc-firstname"
                >
                  <b-form-input
                    id="mc-firstname"
                    v-model="user.firstname"
                    placeholder="First Name"
                  />
                </b-form-group>
              </b-col>

              <!-- Middle Name -->
              <b-col md="6">
                <b-form-group
                  label="Middle Name"
                  label-for="mc-middlename"
                >
                  <b-form-input
                    id="mc-middlename"
                    v-model="user.middlename"
                    placeholder="Middle Name"
                  />
                </b-form-group>
              </b-col>

              <!-- Last Name -->
              <b-col md="6">
                <b-form-group
                  label="Last Name"
                  label-for="mc-lastname"
                >
                  <b-form-input
                    id="mc-lastname"
                    v-model="user.lastname"
                    placeholder="Last Name"
                  />
                </b-form-group>
              </b-col>

              <!-- BirthDay -->
              <b-col md="6">
                <b-form-group
                  label="BirthDay"
                  label-for="mc-birthday"
                >
                  <cleave
                    id="mc-birthday"
                    v-model="user.birthday"
                    :options="options.date"
                    class="form-control"
                    :raw="false"
                    placeholder="YYYY-MM-DD"
                  />
                </b-form-group>
              </b-col>

              <!-- Gender -->
              <b-col md="6">
                <b-form-group
                  label="Gender"
                  label-for="mc-sex"
                >
                  <v-select
                    id="mc-sex"
                    v-model="user.sex"
                    :options="genderTypes"
                    placeholder="Gender"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Email -->
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="mc-email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="user.email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>

              <!-- Phone -->
              <b-col md="6">
                <b-form-group
                  label="Phone"
                  label-for="mc-phone"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <cleave
                      id="mc-phone"
                      v-model="user.phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="1234 567 8900"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Home Phone -->
              <b-col md="6">
                <b-form-group
                  label="Home Phone"
                  label-for="mc-home_phone"
                >
                  <b-form-input
                    id="mc-home_phone"
                    v-model="user.home_phone"
                    placeholder="Home Phone"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Company -->
              <b-col
                v-if="role ==='Super Admin'"
                md="6"
              >
                <b-form-group
                  label="Company"
                  label-for="mc-company_id"
                >
                  <v-select
                    id="mc-type"
                    v-model="user.company_id"
                    :options="companies"
                    :reduce="company => company.id"
                    label="name"
                    placeholder="Company"
                    class="w-100"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Role ID -->
              <b-col md="6">
                <b-form-group
                  label="Role"
                  label-for="mc-role"
                >
                  <v-select
                    id="mc-role"
                    v-model="user.role_id"
                    label="name"
                    :options="roles"
                    :reduce="role => role.id"
                    placeholder="Role"
                    class="w-100"
                    @input="changeRole"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Nick Name 1 -->
              <b-col md="6">
                <b-form-group
                  label="Nick Name 1"
                  label-for="mc-nickName1"
                >
                  <b-form-input
                    id="mc-nickName1"
                    v-model="user.nickName1"
                    placeholder="Nick Name 1"
                  />
                </b-form-group>
              </b-col>

              <!-- Nick Name 2 -->
              <b-col md="6">
                <b-form-group
                  label="Nick Name 2"
                  label-for="mc-nickName2"
                >
                  <b-form-input
                    id="mc-nickName2"
                    v-model="user.nickName2"
                    placeholder="Nick Name 2"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Address -->
              <b-col md="6">
                <b-form-group
                  label="Address"
                  label-for="mc-address"
                >
                  <b-form-input
                    id="mc-address"
                    v-model="user.address"
                    placeholder="Address"
                  />
                </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                <b-form-group
                  label="City"
                  label-for="mc-city"
                >
                  <b-form-input
                    id="mc-city"
                    v-model="user.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                <b-form-group
                  label="State"
                  label-for="mc-state"
                >
                  <b-form-input
                    id="mc-state"
                    v-model="user.state"
                    placeholder="State"
                  />
                </b-form-group>
              </b-col>

              <!-- Zipcode -->
              <b-col md="6">
                <b-form-group
                  label="Zipcode"
                  label-for="mc-zipcode"
                >
                  <b-form-input
                    id="mc-zipcode"
                    v-model="user.zipcode"
                    placeholder="Zipcode"
                  />
                </b-form-group>
              </b-col>

              <!-- Username -->
              <b-col md="6">
                <b-form-group
                  label="Username"
                  label-for="mc-username"
                >
                  <b-form-input
                    id="mc-username"
                    v-model="user.username"
                    placeholder="Username"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">
              <!-- Role management -->
              <b-col md="12">
                <role-management-component
                  v-if="user.role_id === 1 || user.role_id === 2 || user.role_id === 3 || user.role_id === 4"
                  :roles="user.custom_roles"
                  @roleChange="roleChange"
                />
              </b-col>

              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card-code>
    </b-col>
    <b-col
      v-if="editId"
      md="12"
    >
      <file-upload-component
        :title="'User Files'"
        :type="'User'"
        :files="files"
      />
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCardBody, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import FileUploadComponent from '@/components/FileUploadComponent.vue'
import * as userService from '@/services/user'
import * as companyService from '@/services/company'
import { mapState } from 'vuex'
import RoleManagementComponent from './RoleManageComponent.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardBody,
    vSelect,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
    FileUploadComponent,
    RoleManagementComponent,
  },
  data() {
    return {
      user: {
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        role_id: '',
        phone: '',
        home_phone: '',
        company_id: '',
        nickName1: '',
        nickName2: '',
        birthday: '',
        sex: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        username: '',
        password: '',
        custom_roles: '',
      },
      userTypes: ['Admin'],
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      genderTypes: ['Male', 'Female'],
      companies: [],
      roles: [],
      files: [],
      editId: '',
    }
  },
  computed: {
    ...mapState({
      role: state => state.auth.role,
      companyId: state => state.auth.companyId,
    }),
  },
  created() {
    this.getRoles()
    this.getCompanyName()
    if (this.$route.name === 'edit-user') {
      this.editId = this.$route.params.id
      this.getUserById(this.$route.params.id)
    }
  },
  methods: {
    // store user
    submit() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          if (this.$route.name === 'add-user') {
            this.create()
          } else {
            this.update()
          }
        }
      })
    },

    // create action
    create() {
      this.$store.commit('set_loading_action', true)
      userService.create({
        ...this.user,
        company_id: this.user.company_id ? this.user.company_id : this.companyId,
      })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'user' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // update action
    update() {
      this.$store.commit('set_loading_action', true)
      userService.update({
        ...this.user,
        company_id: this.user.company_id ? this.user.company_id : this.companyId,
        id: this.editId,
      })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'user' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // get Company name and Id
    async getCompanyName() {
      this.companies = await userService.getCompanyName()
    },

    async getCompanyByCompany() {
      this.companies = await companyService.getCompanyByCompany()
    },

    // get Roles
    async getRoles() {
      this.roles = await userService.getRoles()
      if (this.role === 'Admin') {
        this.roles = this.roles.filter(role => role.name !== 'Super Admin')
      } else if (this.role === 'Accountant') {
        this.roles = this.roles.filter(role => role.name !== 'Super Admin' && role.name !== 'Admin' && role.name !== 'Accountant' && role.name !== 'Dispatcher')
      }
    },

    // get User By Id
    getUserById(id) {
      this.$store.commit('set_loading_action', true)
      userService.editUser(id)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.user = res.user
          this.files = res.files
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          console.log(err)
        })
    },

    changeRole(e) {
      switch (e) {
        case 1:
          this.user.custom_roles = 'Profile,Dashboard,Company,Users'
          break
        case 2:
          this.user.custom_roles = 'Profile,Dashboard,Company,Trips,Load Board,Warehouse,Payments,Invoices,Facility,Users,Vendors,Assets,callbackQBO,Report'
          break
        case 3:
          this.user.custom_roles = 'Profile,Trips,Load Board,Warehouse,Invoices,Users,Vendors,Assets,callbackQBO'
          break
        case 4:
          this.user.custom_roles = 'Profile,Trips,Load Board,Warehouse,Vendors'
          break
        default:
          this.user.custom_roles = ''
          break
      }
    },

    roleChange(e) {
      this.user.custom_roles = e.join()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
